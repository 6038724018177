var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company"},[_c('h2',{staticClass:"company__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900"},[_vm._v(" "+_vm._s(_vm.$t('account.company.title'))+" ")]),_c('form',{staticClass:"company__form tw-w-full",attrs:{"disabled":_vm.hasPendingRequest},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
return [_c('ValidationProvider',{ref:"company-name-provider",attrs:{"name":_vm.$t('app.fields.company_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-3",attrs:{"id":"company_name","label":_vm.$t('app.labels.company_name'),"disabled":_vm.hasPendingRequest,"error":invalid && validated,"hint":errors[0],"loader":_vm.$wait.is('fetching company'),"type":"text","name":"company_name","autocomplete":"organization","required":""},model:{value:(_vm.formData.company_name),callback:function ($$v) {_vm.$set(_vm.formData, "company_name", $$v)},expression:"formData.company_name"}})]}}],null,true)}),_c('fieldset',{staticClass:"company__form__address pt-2"},[_c('legend',{staticClass:"tw-hidden"},[_vm._v(" "+_vm._s(_vm.$t('account.company.titles.address'))+" ")]),_c('ValidationProvider',{attrs:{"name":_vm.$t('app.fields.street_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"street_name","label":_vm.$t('app.labels.street_name'),"disabled":_vm.hasPendingRequest,"error":!_vm.$wait.is('fetching company') && invalid && validated,"hint":errors[0],"loader":_vm.$wait.is('fetching company'),"type":"text","name":"street_name","autocomplete":"street-address","required":""},model:{value:(_vm.formData.address.street_name),callback:function ($$v) {_vm.$set(_vm.formData.address, "street_name", $$v)},expression:"formData.address.street_name"}})]}}],null,true)}),_c('div',{staticClass:"company__form__address__aside tw-flex tw-flex-col 2sm:tw-flex-row"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('app.fields.postal_code'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full 2sm:tw-w-1/3 2sm:tw-mr-2 tw-mb-2 2sm:tw-mb-0",attrs:{"id":"postal_code","label":_vm.$t('app.labels.postal_code'),"disabled":_vm.hasPendingRequest,"error":!_vm.$wait.is('fetching company') && invalid && validated,"hint":errors[0],"data-vv-as":_vm.$t('app.fields.postal_code'),"loader":_vm.$wait.is('fetching company'),"type":"text","name":"postal_code","autocomplete":"postal-code","required":""},model:{value:(_vm.formData.address.postal_code),callback:function ($$v) {_vm.$set(_vm.formData.address, "postal_code", $$v)},expression:"formData.address.postal_code"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('app.fields.city'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-w-full 2sm:tw-w-1/3 tw-mb-2 2sm:tw-mb-0 2sm:tw-mr-2",attrs:{"id":"city","label":_vm.$t('app.labels.city'),"disabled":_vm.hasPendingRequest,"error":!_vm.$wait.is('fetching company') && invalid && validated,"hint":errors[0],"data-vv-as":_vm.$t('app.fields.city'),"loader":_vm.$wait.is('fetching company'),"type":"text","name":"city","autocomplete":"address-level2","required":""},model:{value:(_vm.formData.address.city),callback:function ($$v) {_vm.$set(_vm.formData.address, "city", $$v)},expression:"formData.address.city"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":_vm.$t('app.fields.country'),"rules":"required|max:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('country-selector',{staticClass:"2sm:tw-w-1/3",attrs:{"id":"country","label":_vm.$t('app.labels.country'),"error":!_vm.$wait.is('fetching company') && invalid && validated,"hint":errors[0],"disabled":_vm.hasPendingRequest,"items":_vm.countries,"data-vv-as":_vm.$t('app.fields.country'),"loader":_vm.$wait.is('fetching company'),"preferred-countries":['FR', 'BE', 'DE', 'NL', 'ES', 'IT', 'PL', 'LU', 'MC', 'AD'],"color":"#287696","has-name":"","type":"text","name":"country","autocomplete":"country","required":""},model:{value:(_vm.formData.address.country),callback:function ($$v) {_vm.$set(_vm.formData.address, "country", $$v)},expression:"formData.address.country"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"company__form__buttons tw-flex tw-justify-between tw-mt-10"},[_c('ui-button',{attrs:{"disabled":!dirty || _vm.hasPendingRequest,"variant":"link","type":"button"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t('account.buttons.cancel'))+" ")]),_c('ui-button',{staticClass:"company__form__buttons__save tw-mr-2",attrs:{"loading":_vm.$wait.is('updating company'),"disabled":_vm.hasPendingRequest,"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('account.buttons.save'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }